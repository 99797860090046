<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="canEdit('button')"
        @click="edit = true"
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!canEdit('form')"
      @submit.prevent="submit"
    >
      <template>
        <v-autocomplete
          v-model="selectaffiliation"
          :items="affiliations"
          label="所属"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-text-field
          v-model="abbreviation"
          label="略称"
          prepend-icon="mdi-text"
          outlined
          :rules="[Rules.Required]"
        ></v-text-field>
        <TimePicker
          v-model="starttime"
          label="開始時間"
          prepend-icon="mdi-clock"
          :rules="[Rules.Required]"
          outlined
        />
        <TimePicker
          v-model="endtime"
          label="終了時間"
          prepend-icon="mdi-clock"
          :rules="[Rules.Required]"
          outlined
        />
        <TimePicker
          v-model="breaktime"
          label="休憩時間"
          prepend-icon="mdi-clock"
          :rules="[Rules.Required]"
          outlined
        />
        <v-banner shaped>
          「休憩時間」には「深夜休憩時間」の時間を含めて入力してください。
        </v-banner>
        <TimePicker
          v-model="midnightBreakTime"
          label="深夜休憩時間"
          prepend-icon="mdi-clock"
          :rules="[Rules.Required]"
          outlined
        />
        <!-- <TimePicker
          v-model="daytimeActiveHour"
          label="日中活動時間帯勤務時間"
          prepend-icon="mdi-clock"
          :rules="[Rules.Required]"
          outlined
        /> -->
        <v-text-field
          v-model.number="unitPrice"
          label="単価"
          type="number"
          prepend-icon="mdi-currency-jpy"
          suffix="円"
          outlined
        ></v-text-field>
        <!-- <v-autocomplete
          v-model="selectworkpattern"
          :items="workpatterns"
          label="勤務分類"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-toggle-switch"
          return-object
        >
        </v-autocomplete> -->
        <v-autocomplete
          v-model="midnight"
          :items="execItems"
          label="深夜計算"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="overtime"
          :items="overItems"
          label="残業計算"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <!--v-autocomplete
          v-model="nightshift"
          :items="existeItems"
          label="夜勤有無"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete-->
        <v-autocomplete
          v-model="resultpriority"
          :items="actualItems"
          label="実績優先"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        ></v-autocomplete>
        <!-- <v-autocomplete
          v-model="nightGuardFlag"
          :items="existeItems"
          label="宿直設定"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="enterAfterNightShiftFlag"
          :items="existeItems"
          label="夜勤往復通勤"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="nightTelFlag"
          :items="existeItems"
          label="夜間電話当番"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="timeZoneFlag"
          :items="timeZomeItems"
          label="[フード]警察学校専用:時間帯"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="confirmFlag"
          :items="confirmItems"
          label="[福祉]シフトパターン確定"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete> -->
        <v-text-field
          v-model.number="patternOrder"
          label="表示順"
          type="number"
          prepend-icon="mdi-order-numeric-ascending"
          outlined
        ></v-text-field>
        <v-radio-group
          v-model="selectDelete"
          row
          label="レコード状態:"
          prepend-icon="mdi-toggle-switch"
        >
          <v-radio label="無効" :value="SelectsDelete.Delete"></v-radio>
          <v-radio label="有効" :value="SelectsDelete.Default"></v-radio>
        </v-radio-group>
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import ShiftPattern from "@/models/ShiftPattern";
import { CODE_GROUP as CodeGroup, NOT_SET_CODE as NotSetCode } from "@/defines";
const SelectsDelete = {
  Delete: 0,
  Default: 1
};
export default {
  name: "ShiftPatternDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      values: {},
      conditions: {},
      pattern: [],
      affiliations: [],
      workpatterns: [],
      patternid: "",
      patternname: "",
      abbreviation: "",
      starttime: null,
      endtime: null,
      breaktime: null,
      midnightBreakTime: "00:00",
      daytimeActiveHour: "00:00",
      workclassificationid: 0,
      unitPrice: 0,
      selectaffiliation: { code: "", name: "" },
      selectworkpattern: { code: "0", name: "" },
      midnight: { code: "0", name: "" },
      overtime: { code: "1", name: "" },
      nightshift: { code: "0", name: "" },
      resultpriority: { code: "0", name: "" },
      nightGuardFlag: { code: "0", name: "" },
      enterAfterNightShiftFlag: { code: "0", name: "" },
      nightTelFlag: { code: "0", name: "" },
      timeZoneFlag: { code: "0", name: "" },
      confirmFlag: { code: "0", name: "" },
      patternOrder: 9999,
      execItems: [],
      existeItems: [],
      actualItems: [],
      timeZomeItems: [],
      confirmItems: [],
      overItems: [],
      selectDelete: SelectsDelete.Default,
      SelectsDelete
    };
  },
  computed: {
    Title() {
      return (
        this.title + " " + (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    canEdit(param) {
      const authority = this.$store.state.authority;
      if (authority !== "3") {
        return this.edit;
      }
      if (this.confirmFlag.code === "1") {
        return param === "button" ? true : false;
      } else {
        return this.edit;
      }
    },
    async postRecord(param) {
      const data = await this.$post(this.Paths.shiftpattern, param);
      this.patternid = data.shiftPatternId;
      console.log("generate id", this.patternid);
      this.add = false;
    },
    async putRecord(param) {
      await this.$put(this.Paths.shiftpattern, param);
    },
    complete() {
      console.log("complete", this.patternname);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit");
      this.$loading();
      try {
        const param = new ShiftPattern(
          this.patternid,
          this.selectaffiliation.code,
          this.abbreviation,
          this.abbreviation,
          this.starttime,
          this.endtime,
          this.breaktime,
          this.midnightBreakTime,
          this.daytimeActiveHour,
          this.midnight.code,
          this.overtime.code,
          this.nightshift.code,
          this.resultpriority.code,
          this.nightGuardFlag.code,
          this.enterAfterNightShiftFlag.code,
          this.nightTelFlag.code,
          this.timeZoneFlag.code,
          this.patternOrder,
          this.confirmFlag.code,
          this.unitPrice,
          this.selectDelete
        );

        console.log("submit_param", param);
        if (this.add) {
          await this.postRecord(param);
        } else {
          await this.putRecord(param);
        }
        this.$info("更新しました。", "シフトパターン");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    back() {
      const path = "/list/shift-pattern";
      // this.$router.push(path);
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    async getShozoku() {
      console.log("getShozoku");
      return await this.$get(this.Paths.shozokuFilter);
    },
    async getWorkClassification() {
      console.log("getWorkClassification");
      return await this.$get(this.Paths.workClassification);
    },
    async getCodeMasterItems() {
      console.log("getCodeMasterItems");
      return await this.$get(this.Paths.codeMasterPulldown);
    }
  },
  async created() {
    console.log(this.name, "created");
    this.params = this.$route.params;
    console.log("filter", this.params.filter);
    this.$loading();
    try {
      if (this.prosess && typeof this.prosess === "string")
        this.prosess = this.prosess.toLowerCase();

      switch (this.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      // 所属プルダウンデータ取得
      this.affiliations = await this.getShozoku();
      const masters = await this.getCodeMasterItems();

      this.execItems = masters.filter(e => e.refcode === CodeGroup.EXEC);
      this.existeItems = masters
        .filter(e => e.refcode === CodeGroup.EXISTE)
        .filter(e => e.code !== NotSetCode.EXISTE);
      this.actualItems = masters.filter(e => e.refcode === CodeGroup.ACTUAL);
      this.timeZomeItems = masters.filter(e => e.refcode === CodeGroup.TZONE);
      this.confirmItems = masters.filter(e => e.refcode === CodeGroup.CONFIR);
      this.overItems = masters.filter(e => e.refcode === CodeGroup.OVER);

      if (!this.add) {
        this.patternid = this.args.shiftPatternId;
        this.affiliations = this.affiliations.filter(
          e => e.code === String(this.args.affiliationId)
        );
        if (this.affiliations.length > 0) {
          this.selectaffiliation.code = this.affiliations[0].code;
          this.selectaffiliation.name = this.affiliations[0].name;
        }

        this.patternname = this.args.shiftPatternName;
        this.abbreviation = this.args.abbreviation;
        this.starttime = this.args.startTime;
        this.endtime = this.args.endTime;
        console.log("breaktime", this.args.breakTime);
        this.breaktime = this.args.breakTime;
        this.midnightBreakTime = this.args.midnightBreakTime;
        this.daytimeActiveHour =
          this.args.daytimeActiveHour == null
            ? "00:00"
            : this.args.daytimeActiveHour;
        let val =
          this.args.midnightTimeFlag == null ? "0" : this.args.midnightTimeFlag;
        let filterVal = this.execItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.midnight.code = filterVal[0].code;
          this.midnight.name = filterVal[0].name;
        }

        val = this.args.overTimeFlag == null ? "0" : this.args.overTimeFlag;
        filterVal = this.overItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.overtime.code = filterVal[0].code;
          this.overtime.name = filterVal[0].name;
        }

        val = this.args.nightShiftFlag == null ? "0" : this.args.nightShiftFlag;
        filterVal = this.existeItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.nightshift.code = filterVal[0].code;
          this.nightshift.name = filterVal[0].name;
        }

        val =
          this.args.resultPriorityFlag == null
            ? "0"
            : this.args.resultPriorityFlag;
        filterVal = this.actualItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.resultpriority.code = filterVal[0].code;
          this.resultpriority.name = filterVal[0].name;
        }

        val = this.args.nightGuardFlag == null ? "0" : this.args.nightGuardFlag;
        filterVal = this.existeItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.nightGuardFlag.code = filterVal[0].code;
          this.nightGuardFlag.name = filterVal[0].name;
        }

        val =
          this.args.enterAfterNightShiftFlag == null
            ? "0"
            : this.args.enterAfterNightShiftFlag;
        filterVal = this.existeItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.enterAfterNightShiftFlag.code = filterVal[0].code;
          this.enterAfterNightShiftFlag.name = filterVal[0].name;
        }

        val = this.args.nightTelFlag == null ? "0" : this.args.nightTelFlag;
        filterVal = this.existeItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.nightTelFlag.code = filterVal[0].code;
          this.nightTelFlag.name = filterVal[0].name;
        }
        this.patternOrder = this.args.patternOrder;

        this.unitPrice = this.args.unitPrice;

        val = this.args.timeZone === null ? "0" : this.args.timeZone;
        filterVal = this.timeZomeItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.timeZoneFlag.code = filterVal[0].code;
          this.timeZoneFlag.name = filterVal[0].name;
        }

        val =
          this.args.workClassificationId === null
            ? "0"
            : String(this.args.workClassificationId);
        filterVal = this.confirmItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.confirmFlag.code = filterVal[0].code;
          this.confirmFlag.name = filterVal[0].name;
        }

        if (this.args.isValid === "0") {
          this.selectDelete = this.SelectsDelete.Delete;
        }
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-banner__wrapper {
  padding-top: 0px !important;
}
</style>
